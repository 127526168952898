.compliance-team-dashboard-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  grid-gap: 20px;
}

.compliance-block-header {
  display: grid;
  grid-template-columns: 1fr auto;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.18), 0 1px 2px rgba(0, 0, 0, 0.22);
  align-items: center;
  padding: 5px;
  color: #085b55;
  background: #e8e8e8;
  border-radius: 5px 5px 0 0;
  font-weight: 700;
  font-size: 1.5em;
  padding-left: 10px;
  > span {
    cursor: pointer;
  }
}

.compliance-block {
  padding-bottom: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.compliance-coach-status .coach-status-table {
  height: auto;
}
